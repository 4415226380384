<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('ENTRIES')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text color='secondary' @click='detailsDialog = true'>
        <v-icon class='mr-2'>
          mdi-account-search-outline
        </v-icon>
        {{$t('SEARCH')}}
      </v-btn>
    </v-row>
    <v-divider class='my-3' />

    <!-- ------------------------------------------------- -->
    <!-- Filtro de datas -->
    <!-- ------------------------------------------------- -->
    <v-row no-gutters class='my-3'>
      <v-toolbar flat>
        <v-menu
          v-if='filter === 3'
          ref='menu'
          v-model='menu'
          :close-on-content-click='false'
          :return-value.sync='date'
          transition='scale-transition'
          offset-y
          min-width='auto'
        >
          <template #activator='{ on, attrs }'>
            <div
              v-bind='attrs'
              v-on='on'
            >
              <v-chip color='warning white--text' style='cursor: pointer'>
                {{(date && formattedDate(date[0])) || '---'}}
                <span class='mx-3'>
                  {{$t('DATE_FROM_TO')}}
                </span>
                {{(date && formattedDate(date[1])) || '---'}}
              </v-chip>
            </div>
          </template>
          <v-date-picker
            v-model='date'
            color='secondary'
            no-title
            scrollable
            show-current
            range
            :max='new Date().toISOString().substr(0, 10)'
          >
            <v-spacer />
            <v-btn
              text
              color='primary'
              @click='menu = false'
            >
              {{$t('CANCEL')}}
            </v-btn>
            <v-btn
              text
              color='primary'
              @click='changeDate'
            >
              {{$t('CONFIRM')}}
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-spacer />

        <v-btn-toggle
          v-model='filter'
          mandatory
          color='secondary'
          @change='changeFilter'
        >
          <v-btn>
            {{$t('TODAY')}}
          </v-btn>
          <v-btn>
            {{$t('LAST_X_DAYS', { value: 3 })}}
          </v-btn>
          <v-btn>
            {{$t('LAST_X_DAYS', { value: 7 })}}
          </v-btn>
          <v-btn>
            {{$t('CUSTOM')}}
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- Sem dados -->
    <!-- ---------------------------------------------------------------- -->
    <v-row v-if='!(list && list.issues && list.issues.length > 0) && !(list && list.entries && list.entries.length > 0) && !(list && list.entriesInProgress && list.entriesInProgress.length > 0)' no-gutters class='mt-10'>
      <v-card flat width='100%' height='150'>
        <v-row no-gutters align='center' justify='center' style='height: 150px;'>
          {{$t('NO_ENTRIES')}}
        </v-row>
      </v-card>
    </v-row>

    <v-expansion-panels mandatory flat>
      <!-- ------------------------------------------------- -->
      <!-- Cadastros em andamento -->
      <!-- ------------------------------------------------- -->
      <v-expansion-panel
        v-if='list && list.entriesInProgress && list.entriesInProgress.length > 0'
      >
        <v-expansion-panel-header expand-icon='mdi-menu-down'>
          <v-list-item>
            <v-list-item-avatar size='40' color='warning'>
              <v-icon dark>
                mdi-account-clock-outline
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{$t('ENTRIES_IN_PROGRESS_VALUE', { value: (list && list.entriesInProgress.length) || 0 })}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            flat
            class='wd-100'
          >
            <v-card-title class='py-4'>
              <v-spacer />

              <v-text-field
                v-model='searchInProgress'
                append-icon='mdi-magnify'
                :label='$t("SEARCH")'
                single-line
                hide-details
                clearable
              />
            </v-card-title>

            <v-data-table
              class='wd-100'
              multi-sort
              :search='searchInProgress'
              :headers='headers.inProgress'
              :items='list.entriesInProgress'
              :footer-props='{
                itemsPerPageOptions: [10, 50, 100, 300, -1]
              }'
            >
              <template #item.enrolled='{ item }'>
                <v-tooltip bottom>
                  <template #activator='{ on }'>
                    <v-avatar size='30' :color='item.enrolled ? "success" : "error"' v-on='on' />
                  </template>
                  {{item.enrolled ? $t('ENROLLED') : $t('NOT_ENROLLED')}}
                </v-tooltip>
              </template>

              <template #item.created_at='{ item }'>
                <span>
                  {{formattedDateAndTime(item.created_at)}}
                </span>
              </template>

              <template #item.actions='{ item }'>
                <v-menu
                  bottom
                  left
                >
                  <template #activator='{ on, attrs }'>
                    <v-btn
                      icon
                      v-bind='attrs'
                      v-on='on'
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list class='pa-0'>
                    <v-list-item @click='searchPerson(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-account-search-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('SEARCH_PERSON_DETAILS')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click='checkStatus(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-list-status</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('CHECK_STATUS')}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ------------------------------------------------- -->
      <!-- Cadastros finalizados com problema -->
      <!-- ------------------------------------------------- -->
      <v-expansion-panel
        v-if='list && list.issues && list.issues.length > 0'
      >
        <v-expansion-panel-header expand-icon='mdi-menu-down'>
          <v-list-item>
            <v-list-item-avatar size='40' color='error'>
              <v-icon dark>
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{$t('ENTRIES_WITH_ISSUE', { value: (list && list.issues.length) || 0 })}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            flat
            class='wd-100'
          >
            <v-card-title class='py-4'>
              <v-spacer />

              <v-text-field
                v-model='searchIssues'
                append-icon='mdi-magnify'
                :label='$t("SEARCH")'
                single-line
                hide-details
                clearable
              />
            </v-card-title>

            <v-data-table
              class='wd-100'
              multi-sort
              :search='searchIssues'
              :headers='headers.issues'
              :items='list.issues'
              :footer-props='{
                itemsPerPageOptions: [10, 50, 100, 300, -1]
              }'
            >
              <template #item.enrolled='{ item }'>
                <v-tooltip bottom>
                  <template #activator='{ on }'>
                    <v-avatar size='30' :color='item.enrolled ? "success" : "error"' v-on='on' />
                  </template>
                  {{item.enrolled ? $t('ENROLLED') : $t('NOT_ENROLLED')}}
                </v-tooltip>
              </template>

              <template #item.created_at='{ item }'>
                <span>
                  {{formattedDateAndTime(item.created_at)}}
                </span>
              </template>

              <template #item.status='{ item }'>
                <v-tooltip left>
                  <template #activator='{ on }'>
                    <v-chip
                      outlined
                      color='warning'
                      class='ml-1'
                      v-on='on'
                    >
                      {{item.message_status !== '200 OK' ? item.message_status: item.status}}
                    </v-chip>
                  </template>
                  {{item.message_status !== '200 OK' ? item.message_status: item.status}}
                </v-tooltip>
              </template>

              <template #item.actions='{ item }'>
                <v-menu
                  bottom
                  left
                >
                  <template #activator='{ on, attrs }'>
                    <v-btn
                      icon
                      v-bind='attrs'
                      v-on='on'
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list class='pa-0'>
                    <v-list-item @click='searchPerson(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-account-search-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('SEARCH_PERSON_DETAILS')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click='checkStatus(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-list-status</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('CHECK_STATUS')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click='retryCommit(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-reload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('RETRY')}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ------------------------------------------------- -->
      <!-- Cadastros finalizados sem problemas -->
      <!-- ------------------------------------------------- -->
      <v-expansion-panel
        v-if='list && list.entries && list.entries.length > 0'
      >
        <v-expansion-panel-header expand-icon='mdi-menu-down'>
          <v-list-item>
            <v-list-item-avatar size='40' color='primary lighten-2'>
              <v-icon dark>
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{$t('ENTRIES_WITH_COUNTER', { value: (list && list.entries.length) || 0 })}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            flat
            class='wd-100'
          >
            <v-card-title class='py-4'>
              <v-spacer />

              <v-text-field
                v-model='search'
                append-icon='mdi-magnify'
                :label='$t("SEARCH")'
                single-line
                hide-details
                clearable
              />
            </v-card-title>

            <v-data-table
              class='wd-100'
              multi-sort
              :search='search'
              :headers='headers.items'
              :items='list.entries'
              :footer-props='{
                itemsPerPageOptions: [10, 50, 100, 300, -1]
              }'
            >
              <template #item.enrolled='{ item }'>
                <v-tooltip bottom>
                  <template #activator='{ on }'>
                    <v-avatar size='30' :color='item.enrolled ? "success" : "error"' v-on='on' />
                  </template>
                  {{item.enrolled ? $t('ENROLLED') : $t('NOT_ENROLLED')}}
                </v-tooltip>
              </template>

              <template #item.created_at='{ item }'>
                <span>
                  {{formattedDateAndTime(item.created_at)}}
                </span>
              </template>

              <template #item.actions='{ item }'>
                <v-menu
                  bottom
                  left
                >
                  <template #activator='{ on, attrs }'>
                    <v-btn
                      icon
                      v-bind='attrs'
                      v-on='on'
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list class='pa-0'>
                    <v-list-item @click='searchPerson(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-account-search-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('SEARCH_PERSON_DETAILS')}}</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click='checkStatus(item)'>
                      <v-list-item-icon class='mr-3'>
                        <v-icon>mdi-list-status</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{$t('CHECK_STATUS')}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <!-- Details Dialog -->
    <v-dialog v-model='detailsDialog' fullscreen persistent>
      <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
        <v-app-bar dark color='primary'>
          <v-tooltip bottom>
            <template #activator='{ on }'>
              <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('CLOSE')}}</span>
          </v-tooltip>

          <div class='text-h5 ml-2'>
            {{$t('ENTRY')}}
          </div>
        </v-app-bar>

        <entry-details :cpf='selectedPerson && selectedPerson.cpf' :renach='selectedPerson && selectedPerson.renach' @close='closeDetailsDialog' @reload='getData' />
      </v-card>
    </v-dialog>

    <!-- Workflow dialog -->
    <v-dialog v-model='workflowDialog.active' max-width='45%'>
      <v-card v-if='workflowDialog.active' style='background-color: var(--v-background-base);'>
        <v-app-bar dark color='primary'>
          <v-tooltip bottom>
            <template #activator='{ on }'>
              <v-btn icon v-on='on' @click.native='workflowDialog.active = false'>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('CLOSE')}}</span>
          </v-tooltip>

          <div class='text-h7 ml-2'>
            {{ `${workflowDialog.person.full_name} - ${workflowDialog.person.cpf} - ${workflowDialog.person.renach} em ${formattedDateAndTime(workflowDialog.person.created_at)}` }}
          </div>
        </v-app-bar>

        <workflow :person-id='workflowDialog.person.id' @close='closeWorfklowDialog' @keydown.esc='closeWorfklowDialog' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'Entry',
    components: {
      EntryDetails: () => import('./EntryDetails'),
      Workflow: () => import('../Workflow/Workflow'),
    },
    data: function () {
      return {
        detailsDialog: false,
        selectedPerson: undefined,
        filter: 0,
        menu: undefined,
        date: undefined,
        searchInProgress: undefined,
        searchIssues: undefined,
        search: undefined,
        list: undefined,
        headers: {
          inProgress: [
            {
              text: '-',
              value: 'enrolled',
              width: '30',
              align: 'center',
            },
            { text: this.$t('NAME'), value: 'full_name' },
            { text: this.$t('CPF'), value: 'cpf' },
            { text: this.$t('RENACH'), value: 'renach' },
            { text: this.$t('CREATED_BY'), value: 'creator_name' },
            { text: this.$t('CREATION_DATE'), value: 'created_at', width: 200 },
            { text: this.$t('AST'), value: 'avg_service_time', width: 100 },
            {
              text: '',
              sortable: false,
              value: 'actions',
              width: '30',
              align: 'center',
            },
          ],
          issues: [
            { text: this.$t('NAME'), value: 'full_name' },
            { text: this.$t('CPF'), value: 'cpf' },
            { text: this.$t('RENACH'), value: 'renach' },
            { text: this.$t('CREATED_BY'), value: 'creator_name' },
            { text: this.$t('CREATION_DATE'), value: 'created_at', width: 200 },
            { text: this.$t('AST'), value: 'avg_service_time', width: 100 },
            {
              text: '',
              sortable: false,
              value: 'actions',
              width: '30',
              align: 'center',
            },
          ],
          items: [
            {
              text: '-',
              value: 'enrolled',
              width: '30',
              align: 'center',
            },
            { text: this.$t('NAME'), value: 'full_name' },
            { text: this.$t('CPF'), value: 'cpf' },
            { text: this.$t('RENACH'), value: 'renach' },
            { text: this.$t('CREATED_BY'), value: 'creator_name' },
            { text: this.$t('CREATION_DATE'), value: 'created_at', width: 200 },
            { text: this.$t('AST'), value: 'avg_service_time', width: 100 },
            {
              text: '',
              sortable: false,
              value: 'actions',
              width: '30',
              align: 'center',
            },
          ],
        },
        workflowDialog: {
          active: false,
          id: undefined,
        },
      };
    },
    mounted: function () {
      this.clearRangeDateFilter();

      this.$nextTick(() => {
        this.getData();
      });
    },
    methods: {
      formattedDateOnlyDayMonth: function (value) {
        return format(parseISO(value), 'dd MMMM', { locale: ptBr });
      },
      formattedDate: function (value) {
        return value && format(parseISO(value), 'dd MMM', { locale: ptBr });
      },
      formattedDateAndTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy HH:mm', { locale: ptBr });
      },
      clearRangeDateFilter: function () {
        const currentDate = new Date();
        const previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - 0);

        this.date = [
          format(previousDate, 'yyyy-MM-dd'),
          format(currentDate, 'yyyy-MM-dd'),
        ];

        this.$nextTick(() => {
          this.getData();
        });
      },
      changeFilter: function () {
        if (this.filter !== 3) {
          let value;

          if (this.filter === 0) {
            value = 0; // dia atual
          } else if (this.filter === 1) {
            value = 3; // 7 dias
          } else {
            value = 7; // 14 dias
          }

          const currentDate = new Date();
          const previousDate = new Date();
          previousDate.setDate(previousDate.getDate() - value);

          this.date = [
            format(previousDate, 'yyyy-MM-dd'),
            format(currentDate, 'yyyy-MM-dd'),
          ];

          this.$nextTick(() => {
            this.getData();
          });
        }
      },
      changeDate: function () {
        if (this.date) {
          this.date = this.date[1] > this.date[0] ? [this.date[0], this.date[1]] : [this.date[1], this.date[0]];

          this.getData();
        }
        this.$refs.menu.save(this.date);
      },
      getData: async function () {
        try {
          const { data } = await axios({
            url: '/entry',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
            params: {
              firstDate: this.date[0],
              secondDate: this.date[1],
            },
          });

          this.list = data;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      searchPerson: function (item) {
        this.selectedPerson = item;
        this.detailsDialog = true;
      },
      closeDetailsDialog: function () {
        this.detailsDialog = false;
        this.selectedPerson = undefined;
      },
      checkStatus: function (item) {
        this.workflowDialog.person = item;
        this.workflowDialog.active = true;
      },
      closeWorfklowDialog: function () {
        this.workflowDialog.person = undefined;
        this.workflowDialog.active = false;
      },
      retryCommit: async function (item) {
        try {
          await axios({
            url: '/entry-commit',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              id: item.id,
            },
          });

          this.getData();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
