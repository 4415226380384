<template>
  <div>
    <v-container v-if='!list || (list && list.length === 0)' fill-height fluid style='height: 90vh'>
      <!-- ---------------------------------------------------------------- -->
      <!-- Busca via CPF/Renach -->
      <!-- ---------------------------------------------------------------- -->
      <v-row no-gutters align='center' justify='center' class='mt-2'>
        <v-card tile flat width='700' color='white'>
          <v-card-text>
            <v-form ref='form'>
              <v-text-field
                v-model='entry.cpf'
                v-mask:bind='cpfMask'
                outlined
                dense
                clearable
                :label='$t("CPF")'
                :rules='[rules.validCpf]'
                hide-details='auto'
                validate-on-blur
                maxlength='14'
                @keyup.enter='search'
              />
              <v-text-field
                v-model='entry.renach'
                outlined
                dense
                clearable
                prefix='AM'
                :label='$t("RENACH")'
                class='mt-4'
                hide-details='auto'
                maxlength='9'
                persistent-hint
                validate-on-blur
                :rules='[rules.renachLength]'
                @keyup.enter='search'
                @keydown='entry.renach = entry.renach ? entry.renach.replace(/\D/g, "") : ""'
              />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn depressed block color='secondary' @click='search'>
              {{$t('SEARCH')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>

    <!-- -------------------------------------------------------------------------------- -->
    <!-- Toolbar com detalhes do cpf/renach do candidato e opção para fazer nova pesquisa -->
    <!-- -------------------------------------------------------------------------------- -->
    <v-container v-else fluid>
      <v-toolbar flat>
        <v-row align='center' justify='center'>
          <v-chip class='ml-1'>
            {{`${entry.cpf || '---'} / ${entry.renach || '---'}`}}
          </v-chip>

          <v-spacer />

          <v-btn color='secondary' class='mr-3' depressed @click='clearData'>
            <v-icon class='mx-1'>
              mdi-account-search-outline
            </v-icon>
            {{$t('NEW_SEARCH')}}
          </v-btn>

          <v-btn color='primary' class='mr-3' depressed outlined @click='search'>
            <v-icon class='mx-1'>
              mdi-refresh
            </v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>

      <!-- ---------------------------------------------------------------- -->
      <!-- Dados base de dados -->
      <!-- ---------------------------------------------------------------- -->

      <div v-if='list && ((list.entries && list.entries.length > 0) || list.issues && list.issues.length > 0)' class='mt-5'>
        <v-data-table
          :items='[...list.entries, ...list.issues]'
          :headers='headers.list'
        >
          <template #item.avatar='{ item }'>
            <v-avatar v-if='item.face && item.face[0] && (item.face[0].segmented_data || item.face[0].data)' size='50' class='white'>
              <v-img contain :src='`data:image/charset=UTF-8;png;base64,${item.face[0].segmented_data || item.face[0].data}`' />
            </v-avatar>

            <v-avatar v-else size='50' class='primary lighten-2'>
              <v-icon x-large dark>
                mdi-account
              </v-icon>
            </v-avatar>
          </template>

          <template #item.created_at='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <span v-on='on'>{{formattedDateWithTime(item.created_at)}}</span>
              </template>
              {{$t('CREATED_AT', { value: formattedDateWithTime(item.created_at) })}}
            </v-tooltip>
          </template>

          <!-- <template #item.deleted_at='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <span v-on='on'>{{formattedDateWithTime(item.deleted_at)}}</span>
              </template>
              {{$t('DELETED_AT', { value: formattedDateWithTime(item.deleted_at) })}}
            </v-tooltip>
          </template> -->

          <template #item.info='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='hasSentFaceProdam(item) ? "success mx-1" : "error mx-1"' v-on='on'>
                  <v-icon dark>
                    mdi-emoticon-neutral-outline
                  </v-icon>
                </v-avatar>
              </template>
              {{hasSentFaceProdam(item) ? $t('HAS_FACE_PRODAM') : $t('HAS_NOT_FACE_PRODAM')}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='hasSentFingersProdam(item).count > 0 ? "success mx-1" : "error mx-1"' v-on='on'>
                  <span class='white--text title'>
                    {{hasSentFingersProdam(item).count}}
                  </span>
                </v-avatar>
              </template>
              {{$t('PRODAM_FINGERS', { value: hasSentFingersProdam(item).count })}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='hasSentFaceSerpro(item) ? "success mx-1" : "error mx-1"' v-on='on'>
                  <v-icon dark>
                    mdi-badge-account-outline
                  </v-icon>
                </v-avatar>
              </template>
              {{hasSentFaceSerpro(item) ? $t('HAS_FACE_SERPRO') : $t('HAS_NOT_FACE_SERPRO')}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='hasSentSignatureSerpro(item) ? "success mx-1" : "error mx-1"' v-on='on'>
                  <v-icon dark>
                    mdi-draw
                  </v-icon>
                </v-avatar>
              </template>
              {{hasSentSignatureSerpro(item) ? $t('HAS_SIGNATURE_SERPRO') : $t('HAS_NOT_SIGNATURE_SERPRO')}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='hasSentFingersSerpro(item).all ? "success mx-1" : "error mx-1"' v-on='on'>
                  <v-icon dark>
                    mdi-fingerprint
                  </v-icon>
                </v-avatar>
              </template>
              {{hasSentFingersSerpro(item).all ? $t('HAS_FINGERS_SERPRO') : $t('HAS_NOT_FINGERS_SERPRO')}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-avatar size='30' :class='item.avg_service_time !== "--:--:--" ? "success mx-1" : "error mx-1"' v-on='on'>
                  <v-icon dark>
                    mdi-clock-time-two-outline
                  </v-icon>
                </v-avatar>
              </template>
              {{`${$t('AST')}: ${item.avg_service_time}`}}
            </v-tooltip>
          </template>

          <template #item.enrolled='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-chip
                  small
                  outlined
                  :color='item.deleted_at ? "error" : (item.enrolled ? "success" : "warning")'
                  class='ml-2'
                  v-on='on'
                >
                  {{item.deleted_at ? $t('ENTRY_DELETED') : (item.enrolled ? $t('ENTRY_ENROLLED') : $t('ENTRY_NOT_ENROLLED'))}}
                </v-chip>
              </template>
              {{item.deleted_at ? $t('ENTRY_DELETED') : (item.enrolled ? $t('ENTRY_ENROLLED') : $t('ENTRY_NOT_ENROLLED'))}}
            </v-tooltip>
          </template>

          <template #item.details='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn
                  fab
                  depressed
                  color='primary'
                  x-small
                  class='mr-2'
                  @click='openWorkflowDialog(item)'
                  v-on='on'
                >
                  <v-icon>
                    mdi-list-status
                  </v-icon>
                </v-btn>
              </template>
              {{$t('CHECK_STATUS')}}
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn
                  fab
                  depressed
                  color='primary'
                  x-small
                  @click='openDetailsDialog(item)'
                  v-on='on'
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              Detalhes
            </v-tooltip>

            <v-tooltip
              v-if='item.is_last_entry === 1'
              bottom
            >
              <template #activator='{ on }'>
                <v-btn
                  fab
                  depressed
                  color='error'
                  x-small
                  class='ml-2'
                  @click='retryCommit(item, true)'
                  v-on='on'
                >
                  <v-icon>
                    mdi-reload-alert
                  </v-icon>
                </v-btn>
              </template>
              Retry
            </v-tooltip>

            <v-tooltip v-if='!item.deleted_at' bottom>
              <template #activator='{ on }'>
                <v-btn
                  fab
                  depressed
                  color='error'
                  x-small
                  class='ml-2'
                  @click='deletePerson(item)'
                  v-on='on'
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              Excluir cadastro
            </v-tooltip>
          </template>
        </v-data-table>
      </div>

      <span v-if='list && list.legacy && list.legacy.length > 0' class='mt-5'>
        <v-card-title>
          Imagens legado
        </v-card-title>

        <v-data-table :items='[list.legacy.find(i => i.isFace)]' :headers='headers.legacy'>
          <template #item.avatar='{ item }'>
            <v-avatar v-if='item.isFace && item.base64' size='50' class='white'>
              <v-img contain :src='`data:image/charset=UTF-8;png;base64,${item.base64}`' />
            </v-avatar>

            <v-avatar v-else size='50' class='primary lighten-2'>
              <v-icon x-large dark>
                mdi-account
              </v-icon>
            </v-avatar>
          </template>

          <template #item.renach>
            {{entry.renach}}
          </template>

          <template #item.created_at='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <span v-on='on'>{{formattedDateWithTime(item.dt_aquisicao)}}</span>
              </template>
              {{$t('CREATED_AT', { value: formattedDateWithTime(item.dt_aquisicao) })}}
            </v-tooltip>
          </template>

          <template #item.details='{ item }'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn
                  fab
                  depressed
                  color='primary'
                  x-small
                  @click='openLegacyDetailsDialog(item)'
                  v-on='on'
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              Detalhes
            </v-tooltip>
          </template>
        </v-data-table>
      </span>

      <!-- ---------------------------------------------------------------- -->
      <!-- DIALOGS -->
      <!-- ---------------------------------------------------------------- -->
      <v-dialog v-model='detailsDialog' fullscreen persistent>
        <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('ENTRY')}}
            </div>
          </v-app-bar>

          <entry-details-dialog :item.sync='selectedItem' @close='closeDetailsDialog' @info-change='reloadData' />
        </v-card>
      </v-dialog>

      <v-dialog v-model='legacyDetailsDialog' fullscreen persistent>
        <v-card v-if='legacyDetailsDialog' style='background-color: var(--v-background-base);'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='closeLegacyDetailsDialog'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('ENTRY')}}
            </div>
          </v-app-bar>

          <entry-details-legacy-dialog :items='list.legacy' @close='closeLegacyDetailsDialog' />
        </v-card>
      </v-dialog>

      <v-dialog v-model='motiveDialog' max-width='30%' persistent style='z-index: 999999'>
        <v-container class='pa-0'>
          <v-card class='px-2 py-5'>
            <v-textarea
              v-model='motive'
              filled
              outlined
              counter='400'
              :placeholder='$t("INFO_CHANGE_MOTIVE")'
              @keydown='motive = motive ? motive.substr(0, 399) : ""'
            />
            <v-row class='pb-1 mt-1 mr-1 justify-end'>
              <v-btn small class='mr-2' @click='closeMotiveDialog'>
                {{ $t('CANCEL') }}
              </v-btn>
              <v-btn small color='success' @click='submitDeletePerson'>
                {{ $t('CONFIRM') }}
              </v-btn>
            </v-row>
          </v-card>
        </v-container>
      </v-dialog>

      <!-- Workflow dialog -->
      <v-dialog v-model='workflowDialog.active' max-width='45%'>
        <v-card v-if='workflowDialog.active' style='background-color: var(--v-background-base);'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='workflowDialog.active = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h7 ml-2'>
              {{ `${workflowDialog.person.full_name} - ${workflowDialog.person.cpf} - ${workflowDialog.person.renach} em ${formattedDateWithTime(workflowDialog.person.created_at)}` }}
            </div>
          </v-app-bar>

          <workflow :person-id='workflowDialog.person && workflowDialog.person.id' @close='closeWorfklowDialog' @keydown.esc='closeWorfklowDialog' />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
  /* eslint-disable camelcase */
  import axios from 'axios';
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import Swal from 'sweetalert2';
  import { mask } from 'vue-the-mask';
  import { mapGetters } from 'vuex';

  import { cpfValidate } from '../../util/validation/cpf';
  import { showError } from '../../util/notification';

  export default {
    name: 'EntryDetails',
    components: {
      EntryDetailsDialog: () => import('./EntryDetailsDialog'),
      EntryDetailsLegacyDialog: () => import('./EntryDetailsLegacyDialog'),
      Workflow: () => import('../Workflow/Workflow'),
    },
    directives: {
      mask: mask,
    },
    props: {
      cpf: {
        type: String,
        default: '',
      },
      renach: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    data: function () {
      return {
        tab: 0,
        cpfMask: '###.###.###-##',
        entry: {
          cpf: '',
          renach: '',
        },
        list: undefined,
        detailsDialog: false,
        legacyDetailsDialog: false,
        selectedItem: undefined,
        headers: {
          list: [
            {
              text: '',
              value: 'avatar',
              width: '30',
              align: 'center',
            },
            { text: this.$t('CIVIL_NAME'), value: 'full_name' },
            { text: this.$t('RENACH'), value: 'renach' },
            { text: this.$t('CREATION_DATE'), value: 'created_at' },
            // { text: this.$t('DELETED_AT'), value: 'deleted_at' },
            {
              text: '',
              value: 'info',
              width: '280',
              align: 'center',
            },
            {
              text: '',
              value: 'enrolled',
              width: '100',
              align: 'center',
            },
            {
              text: '',
              value: 'details',
              width: '200',
              align: 'center',
            },
          ],
          legacy: [
            {
              text: '',
              value: 'avatar',
              width: '30',
              align: 'center',
            },
            { text: this.$t('RENACH'), value: 'renach' },
            { text: this.$t('CREATION_DATE'), value: 'created_at' },
            {
              text: '',
              value: 'details',
              width: '100',
              align: 'center',
            },
          ],
        },
        rules: {
          validCpf: (value) => !value || (value && cpfValidate(value, true)) || this.$t('INVALID_CPF'),
          renachLength: (value) => !value || (value && value.length === 9) || this.$t('INVALID_RENACH'),
        },
        motiveDialog: false,
        motive: '',
        workflowDialog: {
          active: false,
          person: undefined,
        },
      };
    },
    mounted: function () {
      if (this.cpf && this.renach) {
        this.entry.cpf = this.cpf;
        this.entry.renach = this.renach;

        this.search();
      }
    },
    methods: {
      reloadData: function (cpf) {
        this.entry.cpf = cpf;
        this.search();
        this.selectedItem = this.list.filter((item) => item.id === this.selectedItem.id);
      },
      formattedDate: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy', { locale: ptBr });
      },
      formattedDateWithTime: function (value) {
        return value && format(parseISO(value), 'dd/MM/yyyy HH:mm:ss', { locale: ptBr });
      },
      clearData: function () {
        this.list = undefined;

        this.entry = {
          cpf: undefined,
          renach: undefined,
        };
      },
      search: async function () {
        if (!this.entry.cpf && !this.entry.renach) {
          showError({ message: this.$t('MUST_INFORM_CPF_OR_RENACH') });
          return;
        }

        if ((this.entry.cpf && cpfValidate(this.entry.cpf)) || (this.entry.renach && this.entry.renach.length === 9)) {
          try {
            const { data } = await axios({
              url: '/entry-get-by-renach-cpf',
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'GET',
              responseType: 'json',
              withCredentials: true,
              params: {
                cpf: this.entry.cpf ? this.entry.cpf.replace(/\D/g, '') : undefined,
                renach: this.entry.renach ? `AM${this.entry.renach.replace(/\D/g, '')}` : undefined,
              },
            });

            this.list = data;
          } catch (e) {
            console.log(e); /* eslint-disable-line no-console */
          }
        }
      },
      hasSentFaceSerpro: function (item) {
        return item.serpro && item.serpro.retrato && !!item.serpro.retrato.imagem.base64;
      },
      hasSentSignatureSerpro: function (item) {
        return item.serpro && item.serpro.assinatura && !!item.serpro.assinatura.imagem.base64;
      },
      hasSentFingersSerpro: function (item) {
        if (!item.serpro) {
          return false;
        }

        const {
          anularDireito,
          anularEsquerdo,
          indicadorDireito,
          indicadorEsquerdo,
          medioDireito,
          medioEsquerdo,
          minimoDireito,
          minimoEsquerdo,
          polegarDireito,
          polegarEsquerdo,
        } = item.serpro;

        const hasRightRing = anularDireito && ((anularDireito.imagem && !!anularDireito.imagem.base64) || !!anularDireito.motivoAusencia);
        const hasLeftRing = anularEsquerdo && ((anularEsquerdo.imagem && !!anularEsquerdo.imagem.base64) || !!anularEsquerdo.motivoAusencia);
        const hasRightIndex = indicadorDireito && ((indicadorDireito.imagem && !!indicadorDireito.imagem.base64) || !!indicadorDireito.motivoAusencia);
        const hasLeftIndex = indicadorEsquerdo && ((indicadorEsquerdo.imagem && !!indicadorEsquerdo.imagem.base64) || !!indicadorEsquerdo.motivoAusencia);
        const hasRightMiddle = medioDireito && ((medioDireito.imagem && !!medioDireito.imagem.base64) || !!medioDireito.motivoAusencia);
        const hasLeftMiddle = medioEsquerdo && ((medioEsquerdo.imagem && !!medioEsquerdo.imagem.base64) || !!medioEsquerdo.motivoAusencia);
        const hasRightPinky = minimoDireito && ((minimoDireito.imagem && !!minimoDireito.imagem.base64) || !!minimoDireito.motivoAusencia);
        const hasLeftPinky = minimoEsquerdo && ((minimoEsquerdo.imagem && !!minimoEsquerdo.imagem.base64) || !!minimoEsquerdo.motivoAusencia);
        const hasRightThumb = polegarDireito && ((polegarDireito.imagem && !!polegarDireito.imagem.base64) || !!polegarDireito.motivoAusencia);
        const hasLeftThumb = polegarEsquerdo && ((polegarEsquerdo.imagem && !!polegarEsquerdo.imagem.base64) || !!polegarEsquerdo.motivoAusencia);

        return {
          all: hasRightRing && hasLeftRing && hasRightIndex && hasLeftIndex && hasRightMiddle && hasLeftMiddle && hasRightPinky && hasLeftPinky && hasRightThumb && hasLeftThumb,
          hasRightRing: hasRightRing,
          hasLeftRing: hasLeftRing,
          hasRightIndex: hasRightIndex,
          hasLeftIndex: hasLeftIndex,
          hasRightMiddle: hasRightMiddle,
          hasLeftMiddle: hasLeftMiddle,
          hasRightPinky: hasRightPinky,
          hasLeftPinky: hasLeftPinky,
          hasRightThumb: hasRightThumb,
          hasLeftThumb: hasLeftThumb,
        };
      },
      hasSentFaceProdam: function (item) {
        return item.prodam && item.prodam.imagens && !!item.prodam.imagens.foto;
      },
      hasSentFingersProdam: function (item) {
        const hasRightRing = item.prodam && item.prodam.imagens && !!item.prodam.imagens.anelarDireito;
        const hasLeftRing = item.prodam && item.prodam.imagens && !!item.prodam.imagens.anelarEsquerdo;
        const hasRightIndex = item.prodam && item.prodam.imagens && !!item.prodam.imagens.indicadorDireito;
        const hasLeftIndex = item.prodam && item.prodam.imagens && !!item.prodam.imagens.indicadorEsquerdo;
        const hasRightMiddle = item.prodam && item.prodam.imagens && !!item.prodam.imagens.medioDireito;
        const hasLeftMiddle = item.prodam && item.prodam.imagens && !!item.prodam.imagens.medioEsquerdo;
        const hasRightPinky = item.prodam && item.prodam.imagens && !!item.prodam.imagens.minimoDireito;
        const hasLeftPinky = item.prodam && item.prodam.imagens && !!item.prodam.imagens.minimoEsquerdo;
        const hasRightThumb = item.prodam && item.prodam.imagens && !!item.prodam.imagens.polegarDireito;
        const hasLeftThumb = item.prodam && item.prodam.imagens && !!item.prodam.imagens.polegarEsquerdo;

        return {
          all: hasRightRing && hasLeftRing && hasRightIndex && hasLeftIndex && hasRightMiddle && hasLeftMiddle && hasRightPinky && hasLeftPinky && hasRightThumb && hasLeftThumb,
          hasRightRing: hasRightRing,
          hasLeftRing: hasLeftRing,
          hasRightIndex: hasRightIndex,
          hasLeftIndex: hasLeftIndex,
          hasRightMiddle: hasRightMiddle,
          hasLeftMiddle: hasLeftMiddle,
          hasRightPinky: hasRightPinky,
          hasLeftPinky: hasLeftPinky,
          hasRightThumb: hasRightThumb,
          hasLeftThumb: hasLeftThumb,
          count: [hasRightRing, hasLeftRing, hasRightIndex, hasLeftIndex, hasRightMiddle, hasLeftMiddle, hasRightPinky, hasLeftPinky, hasRightThumb, hasLeftThumb].filter(Boolean).length,
        };
      },
      openDetailsDialog: function (item) {
        this.selectedItem = item;
        this.detailsDialog = true;
      },
      closeDetailsDialog: function () {
        this.detailsDialog = false;
        this.selectedItem = undefined;
      },
      openLegacyDetailsDialog: function () {
        this.legacyDetailsDialog = true;
      },
      closeLegacyDetailsDialog: function () {
        this.legacyDetailsDialog = false;
      },
      openWorkflowDialog: function (item) {
        this.workflowDialog.person = item;
        this.workflowDialog.active = true;
      },
      closeWorkflowDialog: function () {
        this.workflowDialog.person = undefined;
        this.workflowDialog.active = false;
      },
      retryCommit: async function (item, updateBeforeSend) {
        const result = await Swal.fire({
          title: this.$t('WARNING'),
          text: this.$t('REALLY_WANT_COMMIT_RETRY'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM_COMMIT_RETRY'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        if (!result.value) {
          return;
        }

        try {
          await axios({
            url: '/entry-commit',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              id: item.id,
              updateBeforeSend: updateBeforeSend || false,
            },
          });
          this.$emit('reload');

          Swal.fire({
            icon: 'success',
            title: 'Dados enviados para o BCA com sucesso!',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
        } catch (e) {
          const error = e?.error?.message ? e.error.message || e.error.message.menssagem : e.error;
          Swal.fire({
            icon: 'error',
            title: 'Erro ao tentar integração',
            text: `ERRO: ${error}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
        }
      },
      deletePerson: async function (item) {
        const result = await Swal.fire({
          title: this.$t('WARNING'),
          text: this.$t('REALLY_WANT_DELETE_PERSON', { name: item.name }),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM_DELETE_PERSON'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        if (!result.value) {
          return;
        }

        this.selectedItem = item;
        this.motiveDialog = true;
      },
      submitDeletePerson: async function () {
        try {
          await axios({
            url: '/entry-manual-intervention-delete-person',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'DELETE',
            responseType: 'json',
            withCredentials: true,
            data: {
              personId: this.selectedItem.id,
              action: 'DELETE',
              motive: this.motive,
              userId: this.getUser.account.id,
            },
          });
          this.closeMotiveDialog();
          this.search();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      closeMotiveDialog: function () {
        this.motiveDialog = false;
        this.motive = '';
        this.$forceUpdate();
      },
    },
  };
</script>
